export function objGet(obj, key, defaultResponse = undefined) {
    const result = key.split('.').reduce(function(o, x) {
        return (typeof o == 'undefined' || o === null) ? o : o[x];
    }, obj);

    if (result === undefined) {
        return defaultResponse;
    }

    return result;
}
