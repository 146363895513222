<template>
    <div>
        <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="General" name="general">
                <template v-if="hideTempComenzi != true">
                    <div class="mini-separator"></div>
                    <p v-if="shop.platform === 'Magento2' && showError" style="color: darkred">
                        {{ errorMessage }} <a href="" target="_blank" style="color: blue">Download</a>
                    </p>
                    <div class="mini-separator"></div>
                    <el-switch
                        v-model="shop.configs.orders.sync_orders"
                        :active-text="$t('shop.sync_orders_enabled')"
                        :inactive-text="$t('shop.sync_orders_disabled')"
                    >
                    </el-switch>

                    <div class="mini-separator"></div>
                    <treeselect
                        placeholder="Inverval sincronizare comenzi"
                        v-model="shop.configs.orders.sync_order_interval_option"
                        :clearable="false"
                        :disable-branch-nodes="true"
                        :multiple="false"
                        :options="syncOrderIntervalOptions"
                    />
                </template>

                <div class="mini-separator"></div>
                <span v-if="shop.configs.products.sync_only_active_products">{{
                    $t('shop.sync_only_active_products')
                }}</span>
                <span v-else>{{ $t('shop.sync_products') }}</span>
                <el-switch
                    style="margin-left: 5px"
                    v-model="shop.configs.products.sync_only_active_products"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>

                <div v-if="shop.platform === 'Prestashop'" class="mini-separator"></div>
                <span v-if="shop.platform === 'Prestashop'">{{ $t('shop.alternative_description') }}</span>
                <el-switch
                    v-if="shop.platform === 'Prestashop'"
                    style="margin-left: 5px"
                    v-model="shop.configs.products.alternative_description"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>

                <div class="mini-separator"></div>
                <span>{{ $t('shop.original_product_name_to_variations') }}</span>
                <el-switch
                    style="margin-left: 5px"
                    v-model="shop.configs.products.add_original_name_to_variations"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>

                <div class="mini-separator"></div>
                <span>{{ $t('shop.force_tva_value') }}</span>
                <el-switch
                    style="margin-left: 5px"
                    v-model="shop.configs.products.forceHasTva"
                    :active-color="shop.configs.products.forceHasTva === undefined ? '#c0c4cc' : '#13ce66'"
                    :inactive-color="shop.configs.products.forceHasTva === undefined ? '#c0c4cc' : '#ff4949'"
                >
                </el-switch>

                <template v-if="shop.platform === 'Prestashop'">
                    <div class="mini-separator"></div>
                    <span class="demo-input-label">{{ $t('shop.currency') }}</span>
                    <el-select v-model="shop.default_currency_id" class="fullWidth">
                        <el-option
                            v-for="(currency, index) in currencies"
                            :key="index"
                            :value="currency.id"
                            :label="currency.code"
                        ></el-option>
                    </el-select>
                </template>

                <div class="mini-separator"></div>
                <span class="demo-input-label">{{ $t('shop.invoice.prefix') }}</span>
                <el-input :placeholder="$t('shop.invoice.prefix')" v-model="shop.configs.invoice.prefix"> </el-input>

                <div class="mini-separator"></div>
                <span class="demo-input-label">{{ $t('shop.invoice.suffix') }}</span>
                <el-input :placeholder="$t('shop.invoice.suffix')" v-model="shop.configs.invoice.suffix"> </el-input>

                <div class="mini-separator"></div>
                <span class="demo-input-label">{{ $t('shop.invoice.length') }}</span>
                <el-input :placeholder="$t('shop.invoice.length')" v-model="shop.configs.invoice.length"> </el-input>

                <template v-if="shop.platform === 'Magento2'">
                    <div class="mini-separator"></div>
                    <span class="demo-input-label">{{ $t('shop.products.manufacturer_attribute_code') }}</span>
                    <el-input
                        :placeholder="$t('shop.products.manufacturer_attribute_code')"
                        v-model="shop.configs.products.manufacturer_attribute_code"
                    >
                    </el-input>
                </template>

                <div class="mini-separator"></div>
                <el-switch
                    v-model="shop.configs.invoice.use_cel_nr_fact"
                    :active-text="$t('shop.invoice.use_cel_nr_fact')"
                    :inactive-text="$t('shop.invoice.use_cel_nr_fact_no')"
                >
                </el-switch>

                <div class="mini-separator"></div>
                <el-button @click="saveGeneralSettings" type="primary">{{ $t('sells.campaign.save') }}</el-button>
            </el-tab-pane>

            <el-tab-pane
                :label="$t('shop.map_orders_stats')"
                name="map_orders_stats"
                :lazy="true"
                v-if="hideTempComenzi != true"
            >
                <template v-if="ready">
                    <div v-for="(mappedStatus, index) in shop.mapped_orders_statuses" :key="index">
                        <el-row>
                            <div class="el-col-6">
                                <p>{{ celOrderStatuses[mappedStatus.celStatusId].label }}</p>
                            </div>
                            <div class="el-col-18">
                                <treeselect
                                    v-model="shop.mapped_orders_statuses[mappedStatus.celStatusId].shopStatusId"
                                    :clearable="false"
                                    :disable-branch-nodes="true"
                                    :multiple="false"
                                    :options="shopOrderStates"
                                />
                            </div>
                        </el-row>
                    </div>
                </template>

                <el-button @click="saveMappedOrderStatuses" type="primary">{{ $t('shop.save_mapping') }}</el-button>
            </el-tab-pane>

            <el-tab-pane
                v-if="shop.platform === 'Prestashop'"
                :label="$t('shop.map_payments_methods')"
                name="map_payments_methods"
            >
                <el-button @click="saveMappedOrderPayments" type="primary">{{ $t('shop.save_mapping') }}</el-button>
                <el-row v-for="(mappedOrderPayment, index) in shop.mapped_orders_payments_methods" :key="index">
                    <div class="el-col-12">
                        <p v-html="celOrderPayments[mappedOrderPayment.celPaymentId]"></p>
                    </div>
                    <div class="el-col-12">
                        <treeselect
                            v-model="shop.mapped_orders_payments_methods[mappedOrderPayment.celPaymentId].shopPaymentId"
                            :clearable="false"
                            :disable-branch-nodes="true"
                            :multiple="false"
                            :options="shop.payment_modules"
                        />
                    </div>
                </el-row>
                <el-button @click="saveMappedOrderPayments" type="primary">{{ $t('shop.save_mapping') }}</el-button>
            </el-tab-pane>

            <el-tab-pane
                v-if="shop.platform === 'Prestashop'"
                :label="$t('shop.shop_payment_methods')"
                name="shop_payment_methods"
            >
                <el-row>
                    <div class="el-col-md-24 mb-1">
                        <el-button type="success float-right" @click="savePaymentModules">{{
                            $t('sells.campaign.save')
                        }}</el-button>
                        <el-button type="primary float-right mr-1" @click="newPaymentModule">New</el-button>
                        <el-button type="info float-right mr-1" @click="syncPaymentModules">Sync</el-button>
                    </div>
                </el-row>
                <el-row :gutter="20" v-for="(paymentModule, index) in shop.payment_modules" :key="index" class="mb-1">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <span
                                >{{ $t('shop.payment_module_code') }}
                                <small v-if="index === 0">-- Default: "ps_checkpayment"</small></span
                            >
                            <el-input
                                :placeholder="$t('shop.payment_module_code')"
                                v-model="shop.payment_modules[index].id"
                            ></el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <span
                                >{{ $t('shop.payment_module_name') }}
                                <small v-if="index === 0">-- Default: "Payment by check"</small></span
                            >
                            <el-input
                                :placeholder="$t('shop.payment_module_name')"
                                v-model="shop.payment_modules[index].label"
                            ></el-input>
                        </div>
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane
                :label="$t('shop.shop_shipping_methods')"
                name="shop_shipping_methods"
                v-if="hideTempComenzi != true"
            >
                <el-row>
                    <div class="el-col-14">
                        <p style="margin: 5px 0; font-weight: 500">Nume</p>
                    </div>
                    <div class="el-col-10">
                        <p style="margin: 5px 0; font-weight: 500">Prioritate</p>
                    </div>
                </el-row>
                <el-row style="margin: 5px 0" v-for="(shipMethod, index) in shop.shipping_methods" :key="index">
                    <div class="el-col-14">
                        <p style="margin: 5px 0">{{ shipMethod.label }}</p>
                    </div>
                    <div class="el-col-10">
                        <el-input placeholder="Please input" v-model="shop.shipping_methods[index].priority"></el-input>
                    </div>
                </el-row>
                <el-button @click="saveShippingMethods" type="primary">Save</el-button>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Treeselect from '@cel/vue-treeselect';

export default {
    props: {
        shop: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hideTempComenzi: true,
            activeName: 'general',
            showError: false,
            errorMessage: '',
            celOrderStatuses: {},
            shopOrderStates: [],
            ready: false,
            celOrderPayments: [],
            currencies: [],
            syncOrderIntervalOptions: [
                {
                    id: 1,
                    label: 'Actualizare in fiecare ora',
                },
                {
                    id: 2,
                    label: 'Actualizare la 2 ore',
                },
                {
                    id: 3,
                    label: 'Actualizare la 3 ore',
                },
                {
                    id: 6,
                    label: 'Actualizare la 6 ore',
                },
                {
                    id: 12,
                    label: 'Actualizare la 12 ore',
                },
            ],
        };
    },
    components: {
        Treeselect,
    },
    created() {
        this.syncOnlyActiveProducts = this.shop.products.sync_only_active_products;

        this.$store.dispatch('integrations/getCurrencies').then((res) => {
            if (res.error === 0 && res.message.success) {
                this.currencies = res.message.currencies;
            }
        });

        this.$store
            .dispatch('integrations/getOrdersStatuses', {
                _id: this.$route.params.id,
            })
            .then((res) => {
                if (res.error === 0 && res.message.success) {
                    this.shop.mapped_orders_statuses = res.message.mapped_orders_statuses;
                    this.celOrderStatuses = res.message.celOrderStatuses;
                    this.shopOrderStates = res.message.shopOrderStates;
                    this.ready = true;
                }
            })
            .catch((err) => {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'saveGeneralSettings',
                        dispatch: 'integrations/getOrdersStatuses',
                    },
                });
            });

        this.$store
            .dispatch('integrations/getOrdersPaymentsMethods', {
                _id: this.$route.params.id,
            })
            .then((res) => {
                if (res.error === 0 && res.message.success) {
                    this.shop.mapped_orders_payments_methods = res.message.mapped_orders_payments_methods;
                    this.celOrderPayments = res.message.celOrderPayments;
                }
            })
            .catch((err) => {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'saveGeneralSettings',
                        dispatch: 'integrations/getOrdersPaymentsMethods',
                    },
                });
            });

        this.syncOrderIntervalOption =
            typeof this.shop.sync_order_interval_option === 'undefined' ? null : this.shop.sync_order_interval_option;
    },
    methods: {
        saveGeneralSettings() {
            this.$store
                .dispatch('integrations/saveSettings', {
                    _id: this.$route.params.id,
                    platform: this.shop.platform,
                    configs: this.shop.configs,
                })
                .then((res) => {
                    if (res.error === 0 && res.message.success) {
                        this.shop.configs = res.message.configs;
                        if (res.message.notice !== '') {
                            this.$notify.error(res.message.notice);
                        }
                        if (this.shop.platform === 'Magento2') {
                            this.shop.shipping_methods = res.message.shipping_methods;
                        }
                    }
                    if (res.message.showError && this.shop.platform === 'Magento2') {
                        this.showError = true;
                        this.errorMessage = this.$t(`responses.${res.message.message}`);
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'saveGeneralSettings',
                        },
                    });
                });
        },
        saveMappedOrderStatuses() {
            this.$store
                .dispatch('integrations/saveMappedOrderStatuses', {
                    _id: this.$route.params.id,
                    mapped_orders_statuses: this.shop.mapped_orders_statuses,
                })
                .then((res) => {})
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'saveMappedOrderStatuses',
                        },
                    });
                });
        },
        saveMappedOrderPayments() {
            this.$store
                .dispatch('integrations/saveMappedOrderPayments', {
                    _id: this.$route.params.id,
                    mapped_orders_payments_methods: this.shop.mapped_orders_payments_methods,
                })
                .then((res) => {})
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'saveMappedOrderPayments',
                        },
                    });
                });
        },
        newPaymentModule() {
            this.shop.payment_modules.push({
                id: null,
                label: null,
            });
        },
        savePaymentModules() {
            this.$store
                .dispatch('integrations/savePaymentModules', {
                    _id: this.$route.params.id,
                    payment_modules: this.shop.payment_modules,
                })
                .then((res) => {
                    if (res.error === 0 && res.message.success) {
                        this.shop.payment_modules = res.message.paymentModules;
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'savePaymentModules',
                        },
                    });
                });
        },
        syncPaymentModules() {
            this.$store
                .dispatch('integrations/syncPaymentModules', {
                    _id: this.$route.params.id,
                })
                .then((res) => {
                    if (res.error === 0 && res.message.success) {
                        this.shop.payment_modules = res.message.paymentModules;
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'syncPaymentModules',
                        },
                    });
                });
        },
        saveShippingMethods() {
            this.$store
                .dispatch('integrations/saveShippingMethods', {
                    _id: this.$route.params.id,
                    shippingMethods: this.shop.shipping_methods,
                })
                .then((res) => {})
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'syncPaymentModules',
                        },
                    });
                });
        },
    },
};
</script>

<style scoped>
.mini-separator {
    margin: 20px 0;
}
</style>
