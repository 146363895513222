<template>
    <div class="app-container" v-if="getReady">
        <div class="grid-content">
            <p v-if="!createShop">
                <box :type="'info'" :active="true">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li>
                                Toate informatiile necesare pentru integrare magazin se regasesc in tab-ul "<span
                                    class="cursor-pointer"
                                    style="color: dodgerblue"
                                    @click="activeTab = 'info'"
                                    >Info</span
                                >"
                            </li>
                        </ul>
                    </div>
                </box>
            </p>
            <p v-if="!createShop && shop.platform === 'Magento2'">
                Este necesar sa se integreze modulul Cel pentru Magento 2.
                <a href="https://github.com/celdotro/magento2_connector" target="_blank" style="color: dodgerblue"
                    >Download</a
                >
            </p>
            <el-card class="box-card">
                <div slot="header" class="clearfix dashboard-card-title">
                    <svg-icon icon-class="cart" />
                    <span class="head-title" v-if="createShop">Add {{ platform }}</span>
                    <span class="head-title" v-else> {{ shop.name }}</span>
                    <template v-if="!createShop">
                        <router-link :to="{ name: 'shop' }">
                            <el-button plain class="float-right" type="primary">Back</el-button>
                        </router-link>
                        <el-button plain class="float-right mr-1" @click="showOrHideConfig">Config</el-button>
                    </template>
                    <el-button v-else type="warning" class="float-right" @click="back">Back</el-button>
                </div>
                <div class="card-body">
                    <el-tabs
                        v-model="activeTab"
                        @tab-click="handleClick"
                        :before-leave="leave"
                        class="overflow-initial"
                    >
                        <el-tab-pane
                            v-if="showConfigTab"
                            :label="$t('shop.shop_config')"
                            name="shopConfig"
                            :lazy="true"
                        >
                            <PrestashopConfig
                                v-if="getPlatform === 'Prestashop'"
                                :shop.sync="shop"
                                :platform="getPlatform"
                            />
                            <Magento2Config
                                v-if="getPlatform === 'Magento2'"
                                :shop.sync="shop"
                                :platform="getPlatform"
                            />
                            <WooCommerceConfig
                                v-if="getPlatform === 'WooCommerce'"
                                :shop.sync="shop"
                                :platform="getPlatform"
                            />
                            <OpenCartConfig
                                v-if="getPlatform === 'OpenCart'"
                                :shop.sync="shop"
                                :platform="getPlatform"
                            />
                            <ShopifyConfig v-if="getPlatform === 'Shopify'" :shop.sync="shop" :platform="getPlatform" />
                        </el-tab-pane>

                        <el-tab-pane
                            :label="$t('shop.map_cat_char')"
                            name="mapCatChar"
                            class="overflow-initial"
                            :lazy="true"
                            style="min-height: 800px"
                        >
                            <MapCategories :shop.sync="shop" :user="user" />
                        </el-tab-pane>

                        <el-tab-pane :label="$t('sells.order.products')" name="products" :lazy="true">
                            <Products :shop="shop" />
                        </el-tab-pane>

                        <el-tab-pane :label="$t('route.mainsettings')" name="settings" :lazy="true">
                            <Settings :shop.sync="shop" />
                        </el-tab-pane>

                        <el-tab-pane label="Info" name="info" :lazy="true">
                            <el-row style="margin-bottom: 0.5rem" v-if="!createShop">
                                <div class="float-right">
                                    <el-checkbox v-model="shop.configs.accepts.infoTab">
                                        <span :style="{ color: shop.configs.accepts.infoTab ? 'green' : 'red' }"
                                            >Am citit Instructiunile de integrare</span
                                        >
                                    </el-checkbox>
                                    <el-button type="success" round size="mini" @click="saveGeneralSettings"
                                        >Save</el-button
                                    >
                                </div>
                            </el-row>
                            <PrestashopInfo v-if="shop.platform === 'Prestashop'" />
                            <Magento2Info v-if="shop.platform === 'Magento2'" />
                            <WooCommerceInfo v-if="shop.platform === 'WooCommerce'" />
                            <OpenCartInfo v-if="shop.platform === 'OpenCart'" />
                            <ShopifyInfo v-if="shop.platform === 'Shopify'" />
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-card>
        </div>
    </div>
</template>

<style>
.float-right {
    float: right;
}

.p-l-1 {
    padding-left: 1rem;
}

.p-r-1 {
    padding-right: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.border-red {
    border: #f56c6c 1px solid;
    border-radius: 5px;
}

.el-card,
.el-tabs__content {
    overflow: initial !important;
}
.text-danger {
    color: #f56c6c;
}

.w-100 {
    width: 100%;
}
.m-0 {
    margin: 0;
}
.text-blue {
    color: dodgerblue;
}
.text-green {
    color: #00ab00;
}
.text-orange {
    color: orange;
}
.cursor-pointer {
    cursor: pointer;
}
</style>

<script>
import { createIntegrationsStore } from '@/store/modules/integrations/index.js';
import MapCategories from './shop/tabs/MapCategories';
import Products from './shop/tabs/Products';
import Settings from './shop/tabs/Settings';
// import the styles
import '@cel/vue-treeselect/dist/vue-treeselect.css';

export default {
    data() {
        return {
            listaCategoriiParintePermise: [787 /* Carti */],
            activeTab: 'mapCatChar',
            tabClick: false,
            createShop: false,
            showConfigTab: false,
            shop: {
                _id: null,
                name: '',
                platform: '',
                shopUrl: '',
                apiKey: '',
                api_username: '',
                api_password: '',
                api_key: '',
                api_secret: '',
                api_key_oc: '',
                api_key_id: '',
                connected: false,
                categories: [],
                products: {},
                mapped_categories: [],
                product_options: [],
                product_features: [],
                manufacturers: [],
            },
            isReady: false,
            user: {
                celCategories: [],
            },
        };
    },
    props: {
        platform: {
            type: String,
            default: '',
        },
        ready: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getPlatform() {
            if (this.shop.platform !== '') {
                return this.shop.platform;
            }
            return this.platform;
        },
        getReady() {
            if (this.ready) {
                return true;
            }
            return this.isReady;
        },
    },
    components: {
        MapCategories,
        Products,
        Settings,
        PrestashopConfig: () => import('./shops/Prestashop/Tabs/config'),
        Magento2Config: () => import('./shops/Magento2/Tabs/config'),
        WooCommerceConfig: () => import('./shops/WooCommerce/Tabs/config'),
        OpenCartConfig: () => import('./shops/OpenCart/Tabs/config'),
        ShopifyConfig: () => import('./shops/Shopify/Tabs/config'),
        PrestashopInfo: () => import('./shops/Prestashop/Tabs/Info'),
        Magento2Info: () => import('./shops/Magento2/Tabs/Info'),
        WooCommerceInfo: () => import('./shops/WooCommerce/Tabs/info'),
        OpenCartInfo: () => import('./shops/OpenCart/Tabs/info'),
        ShopifyInfo: () => import('./shops/Shopify/Tabs/info'),
        box: () => import('vue-info-box-element'),
    },
    created() {
        const id = this.$route.params.id;
        if (typeof id === 'undefined') {
            this.createShop = true;
            this.showConfigTab = true;
            this.activeTab = 'shopConfig';
            return;
        }

        if (this.createShop) {
            return;
        }

        // Register module
        this.$store.registerModule('integrations', createIntegrationsStore());

        // From Cel MarketPlace
        this.$store
            .dispatch('integrations/getShopData', {
                _id: id,
            })
            .then((res) => {
                if (res.error === 0 && res.message.success) {
                    const elem = this.shop;
                    this.shop = { ...elem, ...res.message.shop };
                    this.isReady = true;

                    // this.shop.product_options = Object.keys(res.message.shop.product_options).map((i) => res.message.shop.product_options[i])
                    // this.shop.product_features = Object.keys(res.message.shop.product_features).map((i) => res.message.shop.product_features[i])

                    // From Customer Shop - if no categories exist in Cel MarketPlace Mongo, perform a request to get user categories
                    if (this.shop.categories.length === 0) {
                        this.$store
                            .dispatch('integrations/getShopCategories', {
                                _id: id,
                            })
                            .then((res) => {
                                if (res.error === 0 && typeof res.message.response.shop !== 'undefined') {
                                    this.shop.categories = res.message.shop.categories;
                                }
                            })
                            .catch((err) => {
                                this.$reportToSentry(err, {
                                    extra: {
                                        fn: 'created',
                                        dispatch: 'integrations/getShopCategories',
                                    },
                                });
                            });
                    }

                    if (this.shop.product_options.length === 0) {
                        this.$store
                            .dispatch('integrations/getShopProductOptions', {
                                _id: id,
                            })
                            .then((res) => {
                                if (res.error === 0 && typeof res.message.response.shop !== 'undefined') {
                                    this.shop.product_options = res.message.shop.product_options;
                                }
                            })
                            .catch((err) => {
                                this.$reportToSentry(err, {
                                    extra: {
                                        fn: 'created',
                                        dispatch: 'integrations/getShopProductOptions',
                                    },
                                });
                            });
                    }

                    if (this.shop.product_features.length === 0) {
                        this.$store
                            .dispatch('integrations/getShopProductCharacteristics', {
                                _id: id,
                            })
                            .then((res) => {
                                if (res.error === 0 && typeof res.message.response.shop !== 'undefined') {
                                    this.shop.product_features = res.message.shop.product_features;
                                }
                            })
                            .catch((err) => {
                                this.$reportToSentry(err, {
                                    extra: {
                                        fn: 'created',
                                        dispatch: 'integrations/getShopProductCharacteristics',
                                    },
                                });
                            });
                    }

                    if (this.shop.manufacturers.length === 0) {
                        this.$store
                            .dispatch('integrations/getShopProductManufacturers', {
                                _id: id,
                            })
                            .then((res) => {
                                if (res.error === 0 && typeof res.message.response.shop !== 'undefined') {
                                    this.shop.manufacturers = res.message.shop.manufacturers;
                                }
                            })
                            .catch((err) => {
                                this.$reportToSentry(err, {
                                    extra: {
                                        fn: 'created',
                                        dispatch: 'integrations/getShopProductManufacturers',
                                    },
                                });
                            });
                    }
                }
                if (res.error && !res.message.success) {
                    this.$router
                        .push({
                            name: 'shop',
                        })
                        .catch(() => {});
                }
            })
            .catch((err) => {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'created',
                        dispatch: 'integrations/getShopProductManufacturers',
                    },
                });
            });

        // From cel Marketplace
        this.$store
            .dispatch('integrations/getSupplierCategories')
            .then((res) => {
                if (res.error === 0) {
                    const categories = res.message.categories;
                    // const celCategories = [];
                    // this.addChildren(categories, celCategories);
                    this.user.celCategories = categories;
                    this.adaugareExceptiiNodes(categories);
                }
            })
            .catch((err) => {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'created',
                    },
                });
            });
    },
    watch: {
        isReady() {
            if (!this.isReady) {
                return;
            }
            setTimeout(() => {
                if (this.createShop || this.shop.configs.accepts.infoTab) {
                    return;
                }
                this.$alert('Va rugam cititi instructiunile de integrare din tabul "Info"', 'Informatii utile', {
                    confirmButtonText: 'OK',
                    callback: (action) => {
                        this.activeTab = 'info';
                    },
                });
            }, 3000);
        },
    },
    beforeDestroy() {
        if (!this.createShop) {
            this.$store.unregisterModule('integrations');
        }
    },
    methods: {
        adaugareExceptiiNodes(array) {
            array.forEach((element) => {
                if (this.listaCategoriiParintePermise.includes(element.id)) {
                    element.isDisabled = 0;
                }
                if (typeof element.children != 'undefined') this.adaugareExceptiiNodes(element.children);
            });
        },

        // General purpose
        addChildren(categories, parentData) {
            for (const property in categories) {
                if (categories.hasOwnProperty(property)) {
                    const tempProperty = property.split('_');

                    const children = [];
                    if (typeof categories[property] === 'object') {
                        this.addChildren(categories[property], children);
                    }

                    const temp = {
                        id: tempProperty[1],
                        label: tempProperty[0],
                    };

                    if (children.length > 0) {
                        temp['children'] = children;
                    }

                    parentData.push(temp);
                }
            }
        },
        handleClick(tab, event) {
            this.tabclick = true;
        },
        back() {
            this.$emit('back', false);
        },
        showOrHideConfig() {
            this.showConfigTab = !this.showConfigTab;
            if (this.showConfigTab) {
                this.activeTab = 'shopConfig';
            } else {
                this.activeTab = 'mapCatChar';
            }
        },
        leave(current, old) {
            let token = '';
            switch (this.shop.platform) {
                case 'Prestashop':
                    token = this.shop.connect_data.apiKey;
                    break;
                case 'Magento2':
                    token = this.shop.connect_data.token;
                    break;
            }

            if (
                old === 'shopConfig' &&
                (this.shop.shopUrl === '' || token === '' || this.shop.name === '' || !this.shop.connect_data.connect)
            ) {
                return false;
            }
        },
        // Shop API
        next() {
            this.tabclick = false;
            this.activeName = 'second';
        },
        saveGeneralSettings() {
            this.$store
                .dispatch('integrations/saveSettings', {
                    _id: this.$route.params.id,
                    platform: this.shop.platform,
                    configs: this.shop.configs,
                })
                .then((res) => {})
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'saveGeneralSettings',
                        },
                    });
                });
        },
    },
};
</script>
