<template>
    <div>
        <el-collapse v-if="shop.platform === 'Prestashop'">
            <el-collapse-item title="Filters" name="1">
                <el-row>
                    <el-button type="primary" class="float-right" @click="getProducts(1)">Apply filters</el-button>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="3">
                        <el-input placeholder="ID" v-model="filters.id"></el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-input :placeholder="$t('shop.reference')" v-model="filters.reference"></el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-input :placeholder="$t('general.name')" v-model="filters.name"></el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-select v-model="filters.active"
                                   placeholder="Select active/disabled"
                                   class="w-100">
                            <el-option
                                    v-for="item in filterOptions.active"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-select
                                v-model="filters.id_category_default"
                                multiple
                                collapse-tags
                                placeholder="Select categories" class="w-100">
                            <el-option
                                    v-for="item in categories"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <el-select
                                v-model="filters.id_manufacturer"
                                multiple
                                collapse-tags
                                placeholder="Select manufacturers" class="w-100">
                            <el-option
                                    v-for="item in shop.manufacturers"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>


                    </el-col>
                </el-row>
            </el-collapse-item>
        </el-collapse>

        <el-table
                v-loading="loading"
                :data="products"
                header-align="center"
                align="center"
                style="width: 100%">
            <el-table-column
                    prop="vendor_ext_id"
                    label="ID">
            </el-table-column>
            <el-table-column
                    prop="products_model"
                    :label="$t('shop.reference')">
            </el-table-column>
            <el-table-column
                    prop="products_name"
                    :label="$t('general.name')">
            </el-table-column>
            <el-table-column
                    prop="manufacturers_name"
                    :label="$t('shop.manufacturer')">
            </el-table-column>
            <el-table-column
                    prop="pretf"
                    :label="$t('products.campaign.price')">
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="Status">
                <template slot-scope="scope">
                    <div class="bullet" :class="{ 'bg-green': scope.row.status == 1, 'bg-red': scope.row.status == 0 }"></div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="virtual"
                    :label="$t('shop.is_virtual')">
                <template slot-scope="scope">
                    <div class="bullet" :class="{ 'bg-green': scope.row.virtual == 1, 'bg-red': scope.row.virtual == 0 }"></div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="actions"
                    label="Actions">
                <template slot-scope="scope">
                    <el-button v-if="shop.platform === 'Prestashop'" type="primary" class="float-right" @click="syncProduct(scope.row.vendor_ext_id)">Sync</el-button>
                    <el-button v-else-if="shop.platform === 'WooCommerce'" type="primary" class="float-right" @click="syncProduct(scope.row.vendor_ext_id)">Sync</el-button>
                    <el-button v-else-if="shop.platform === 'OpenCart'" type="primary" class="float-right" @click="syncProduct(scope.row.vendor_ext_id)">Sync</el-button>
                    <el-button v-else type="primary" class="float-right" @click="syncProduct(scope.row.products_model)">Sync</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-row>
            <el-pagination class="pagination"
                background
                layout="prev, pager, next"
                @current-change="getProducts"
                :current-page.sync="filters.page"
                :page-size="15"
                :total="maxProductsReach">
            </el-pagination>
        </el-row>
    </div>
</template>

<style>
    .bg-green {
        background: #4CD964;
    }

    .bg-red {
        background-color: #dc3545 !important;
    }

     .bullet {
         width: 14px;
         height: 14px;
         border-radius: 81%;
         /*margin: 0 auto;*/
     }

    .pagination {
        text-align: center;
        margin-top: 10px;
    }
</style>

<script>
    export default {
        props: {
            shop: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                products: [],
                loading: true,
                filters: {
                    id: null,
                    reference: null,
                    name: null,
                    active: 'all',
                    id_category_default: null,
                    id_manufacturer: null,
                    page: 1,
                },
                filterOptions: {
                    active: [
                        {
                            id: 'all',
                            label: 'Show all'
                        },
                        {
                            id: 1,
                            label: 'Active'
                        },
                        {
                            id: 0,
                            label: 'Disabled'
                        },
                    ],
                },
                productsTotal: 15,
                maxProductsReach: 15
            };
        },
        computed: {
            categories() {
                return this.shop.categories.filter((item) => item.mapped === true && item.active === true);
            }
        },
        created() {
            this.getProducts(1);
        },
        methods: {
            getProducts(page) {
                this.filters.page = page;
                this.$store.dispatch('integrations/getProducts', {
                    '_id': this.$route.params.id,
                    'filters': this.filters,
                })
                    .then((res) => {
                        this.loading = false;
                        if (res.error === 0 && res.message.success) {
                            this.products = res.message.products;
                            if (res.message.count === 15) {
                                this.productsTotal = (page + 1) * 15;
                                this.maxProductsReach = this.maxProductsReach < this.productsTotal ? this.productsTotal : this.maxProductsReach;
                            } else if (res.message.count < 15) {
                                this.productsTotal = page * 15;
                            }
                        }
                        if (res.error) {
                            this.$notify.error('Error');
                        }
                    }).catch((err) => {
                        this.loading = false;
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'getProducts'
                            }
                        });
                    });
            },
            syncProduct(productId) {
                this.$store.dispatch('integrations/syncProduct', {
                    '_id': this.$route.params.id,
                    productId,
                })
                .then((res) => {
                    if (res.error && !res.message.success) {
                        let errors = '<ul>';
                        res.message.errors.forEach((item) => {
                            errors += `<li>${item.msg}</li>`;
                        });
                        errors += '</ul>';

                        this.$alert(errors, 'Errors', {
                            dangerouslyUseHTMLString: true
                        });
                    }
                    if (res.error === 0 && res.message.success) {
                        this.sbMsg('Done');
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'syncProduct'
                        }
                    });
                });
            }
        }
    };
</script>