<template>
    <el-row>
        <el-dialog
            title="Tutorials"
            :visible.sync="dialogTutorialsVisible"
            width="80%"
            top="3vh"
            custom-class="custom_dialog"
        >
            <el-tabs tab-position="left" style="height: 800px">
                <el-tab-pane :label="tutorial.platform" v-for="(tutorial, index) in tutorials" :key="'t' + index">
                    <el-tabs>
                        <el-tab-pane :label="video.title" v-for="(video, index) in tutorial.videos" :key="'v' + index">
                            <p v-if="typeof video.info !== 'undefined'">{{ video.info }}</p>
                            <iframe width="80%" height="700px" :src="video.youtubeUrl"> </iframe>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
        <div class="el-col-md-24 mb-1">
            <el-button type="success float-right" @click="saveCategoryMappings">{{
                $t('sells.campaign.save')
            }}</el-button>
            <el-button type="primary float-right mr-1" @click="newCategoriesMapping">New</el-button>
            <el-tooltip class="item" effect="dark" :content="$t('shop.sync_shop_categories_info')" placement="top">
                <el-button plain @click="syncShopCategories" style="margin: 0">{{
                    $t('shop.sync_shop_categories')
                }}</el-button>
            </el-tooltip>
            &nbsp;
            <el-tooltip class="item" effect="dark" :content="$t('shop.sync_shop_prod_char_info')" placement="top">
                <el-button plain @click="syncShopProductsCharacteristics" style="margin: 0">{{
                    $t('shop.sync_shop_prod_char')
                }}</el-button>
            </el-tooltip>
            &nbsp;
            <el-tooltip
                class="item"
                effect="dark"
                :content="$t('shop.sync_shop_prod_opt_info')"
                placement="top"
                v-if="shop.configs.products.products_has_options"
            >
                <el-button plain @click="syncShopProductOptions" style="margin: 0">{{
                    $t('shop.sync_shop_prod_opt')
                }}</el-button>
            </el-tooltip>
        </div>

        <div class="el-col-24">
            <div class="el-col-md-2 el-col-lg-2 p-r-1">
                <p>Adaos %</p>
            </div>
            <div class="el-col-md-7 el-col-lg-8 p-r-1">
                <p>Categorii CEL</p>
            </div>
            <div class="el-col-md-7 el-col-lg-8 p-l-1">
                <p>Categorii Magazin</p>
            </div>
        </div>

        <div
            v-for="(mappedCategory, mappedCategoryIndex) in shop.mapped_categories"
            :key="mappedCategoryIndex"
            class="el-col-md-24 mb-1"
        >
            <div class="el-col-md-2 el-col-lg-2" style="padding-right: 10px">
                <el-input
                    placeholder="Adaos"
                    v-model="shop.mapped_categories[mappedCategoryIndex].commission"
                ></el-input>
            </div>
            <div class="el-col-md-7 el-col-lg-8 p-r-1">
                <treeselect
                    :class="{ 'border-red': mappedCategory.celCategory === null }"
                    v-model="shop.mapped_categories[mappedCategoryIndex].celCategory"
                    :clearable="false"
                    :disable-branch-nodes="false"
                    :flat="true"
                    :multiple="false"
                    @open="celCategorySelected"
                    @select="updateAdaos($event, mappedCategoryIndex)"
                    id="select_map_cat"
                    :options="user.celCategories"
                >
                    <label slot="option-label" slot-scope="{ node }" :class="node.isBranch ? 'treeselect_node_el' : ''">
                        {{ node.label }}
                    </label>
                </treeselect>
            </div>
            <div class="el-col-md-7 el-col-lg-8 p-l-1">
                <treeselect
                    :class="{ 'border-red': mappedCategory.shopCategory === null }"
                    v-model="shop.mapped_categories[mappedCategoryIndex].shopCategory"
                    :clearable="false"
                    :disable-branch-nodes="true"
                    :multiple="false"
                    :options="shopCategoriesFiltered"
                />
            </div>
            <div class="el-col-md-1 el-col-lg-1 p-l-1">
                <el-switch
                    style="margin-top: 0.5rem"
                    v-model="shop.mapped_categories[mappedCategoryIndex].active"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
            </div>
            <div class="el-col-md-5 el-col-lg-3 p-l-1">
                <el-tooltip class="item small-margin" effect="dark" content="Remove" placement="top">
                    <el-button type="danger float-right" @click="removeCategoryMapping(mappedCategoryIndex)">
                        <!--                        {{mappedCategoryIndex}}-->
                        <svg-icon icon-class="cancel" />
                    </el-button>
                </el-tooltip>
                <el-tooltip
                    v-if="mappedCategory.celCategory !== null && mappedCategory.shopCategory !== null"
                    class="item small-margin"
                    effect="dark"
                    content="Map Characteristics"
                    placement="top"
                >
                    <el-button
                        type="info float-right mr-1"
                        @click="showCharTab(mappedCategoryIndex, mappedCategory.celCategory)"
                    >
                        <!--                        {{mappedCategoryIndex + mappedCategory.celCategory}}-->
                        <svg-icon icon-class="list" />
                    </el-button>
                </el-tooltip>
                <el-tooltip class="item small-margin" effect="dark" placement="top">
                    <div slot="content">
                        Numar de produse disponibile in aceasta categorie
                        <span v-if="typeof mappedCategory.count_products !== 'undefined'">{{
                            mappedCategory.count_products
                        }}</span
                        >. <br />Valoarea afisata nu poate sa fie mai mare de 200 (pot exista mai mult de 200 produse).
                        <br />Daca sunt 200 sau mai mult de 200 produse se va afisa >200. <br />Va rugam consultati
                        tab-ul info pentru mai multe detalii. <br />Acest numar reprezinta doar produsele
                        simple/principale.
                        <template v-if="shop.platform === 'Prestashop'">
                            <br />Urmariti clipul video daca dati click pe acest buton.
                        </template>
                    </div>
                    <el-button type="warning float-right" @click="openModal">
                        <span v-if="typeof mappedCategory.count_products !== 'undefined'">{{
                            mappedCategory.count_products
                        }}</span>
                        <span v-else>0</span>
                    </el-button>
                </el-tooltip>
            </div>

            <div
                class="el-col-24"
                style="margin: 1rem 0; padding: 0 1rem"
                v-if="shopActiveCategoryMapTab === mappedCategoryIndex"
            >
                <template v-if="celCharacteristics[mappedCategory.celCategory].length">
                    <div class="el-col-24">
                        <el-button
                            type="success"
                            @click="syncCategoryCharacteristics(mappedCategory.celCategory)"
                            style="margin: 0"
                            >{{ $t('shop.sync_shop_cat_chart_opt') }}</el-button
                        >
                    </div>

                    <div class="el-col-24" style="margin: 1rem 0">
                        <div
                            class="p-r-1"
                            :class="{
                                'el-col-8': shop.configs.products.products_has_options,
                                'el-col-12': !shop.configs.products.products_has_options,
                            }"
                        >
                            <el-tooltip class="item" effect="dark" content="Filtre Cel.ro" placement="top">
                                <el-button type="info mr-1 mt-1"> Filtre Cel </el-button>
                            </el-tooltip>
                        </div>

                        <div
                            class="p-r-1"
                            :class="{
                                'el-col-8': shop.configs.products.products_has_options,
                                'el-col-12': !shop.configs.products.products_has_options,
                            }"
                        >
                            <el-tooltip class="item" effect="dark" placement="top">
                                <div slot="content">
                                    Caracteristici Magazin. <br />Acestea sunt disponibile in general pentru produsele
                                    simple. <br />Aceasta coloana este obligatorie daca filtrul cel este obligatoriu.
                                </div>
                                <el-button type="info mr-1 mt-1"> Caracteristici </el-button>
                            </el-tooltip>
                        </div>

                        <div class="el-col-8" v-if="shop.configs.products.products_has_options">
                            <el-tooltip class="item" effect="dark" placement="top">
                                <div slot="content">
                                    Atribute Magazin. <br />Acestea sunt disponibile in general pentru produsele
                                    combinate/variabile. <br />In cazul in care magazinul dumneavoastra are si atribute,
                                    acestea vor avea prioritate. <br />
                                    Mapati atributele doar daca utilizati produsele combinate/variate. <br />Aceasta
                                    coloana nu este obligatorie.
                                </div>
                                <el-button type="info mr-1 mt-1"> Atribute </el-button>
                            </el-tooltip>
                        </div>
                    </div>

                    <div
                        class="el-col-24 on-hov-shadow"
                        style="padding: 0.5rem 0"
                        v-for="(celCategory, celCategoryIndex) in celCharacteristics[mappedCategory.celCategory]"
                        :key="'cat' + celCategoryIndex"
                    >
                        <div
                            class="p-r-1"
                            :class="{
                                'el-col-8': shop.configs.products.products_has_options,
                                'el-col-12': !shop.configs.products.products_has_options,
                            }"
                        >
                            <el-tooltip
                                v-if="celCategory.charact_value !== null"
                                class="item"
                                effect="dark"
                                :content="$t('shop.cel_special_val')"
                                placement="top"
                            >
                                <el-button
                                    type="danger"
                                    style="display: inline-block"
                                    size="mini"
                                    @click="
                                        showCharactInfo = showCharactInfo === celCategoryIndex ? null : celCategoryIndex
                                    "
                                >
                                    <svg-icon icon-class="question_mark" />
                                </el-button>
                            </el-tooltip>
                            <p
                                style="display: inline-block; line-height: 0"
                                :class="{ 'text-danger': celCategory.priority >= 19 }"
                            >
                                {{ celCategory.charact_name }}
                            </p>
                        </div>

                        <div
                            class="p-r-1"
                            :class="{
                                'el-col-8': shop.configs.products.products_has_options,
                                'el-col-12': !shop.configs.products.products_has_options,
                            }"
                        >
                            <treeselect
                                v-model="
                                    celMappedCharacteristics[mappedCategory.celCategory][celCategory.charact_id]
                                        .product_feature
                                "
                                :clearable="true"
                                :disable-branch-nodes="true"
                                :multiple="false"
                                :options="shop.product_features"
                            />
                        </div>

                        <div class="el-col-8" v-if="shop.configs.products.products_has_options">
                            <treeselect
                                v-model="
                                    celMappedCharacteristics[mappedCategory.celCategory][celCategory.charact_id]
                                        .product_option
                                "
                                :clearable="true"
                                :disable-branch-nodes="true"
                                :multiple="false"
                                :options="shop.product_options"
                            />
                        </div>

                        <div
                            class="el-col-24"
                            v-if="celCategory.charact_value !== null && showCharactInfo === celCategoryIndex"
                        >
                            <ul>
                                <li v-for="(val, index) in celCategory.charact_value" :key="index">{{ val }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="el-col-24" v-if="celCharacteristics[mappedCategory.celCategory].length > 15">
                        <el-button
                            type="success"
                            @click="syncCategoryCharacteristics(mappedCategory.celCategory)"
                            style="margin: 0"
                            >{{ $t('shop.sync_shop_cat_chart_opt') }}</el-button
                        >
                    </div>
                </template>
                <template v-else>
                    <el-alert :title="$t('shop.sync_shop_cat_chart_0')" type="warning" :closable="false" />
                </template>
            </div>
        </div>
    </el-row>
</template>

<script>
// import the component
import Treeselect from '@cel/vue-treeselect';
import { objGet } from '../../../../../utils/obj';

export default {
    props: {
        shop: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            shopActiveCategoryMapTab: null,
            celCharacteristics: [],
            celMappedCharacteristics: {},
            showCharactInfo: null,
            dialogTutorialsVisible: false,
            categoriesCommission: {},
            tutorials: [
                {
                    platform: 'Prestashop',
                    videos: [
                        {
                            title: 'Asocierea corecta a categoriilor',
                            youtubeUrl: 'https://www.youtube.com/embed/9_5KI0JO7c0',
                        },
                    ],
                },
            ],
        };
    },
    components: {
        Treeselect,
    },
    computed: {
        shopCategoriesFiltered() {
            const data = [];
            for (let i = 0; i < this.shop.categories.length; i++) {
                let label_select =
                    typeof this.shop.categories[i].slugFormated == 'undefined'
                        ? this.shop.categories[i].label
                        : this.shop.categories[i].slugFormated;

                const tempObject = {
                    id: this.shop.categories[i].id,
                    label: label_select,
                    isDisabled: this.checkIdMapped(this.shop.categories[i].id),
                };
                // For children - recursive categories
                if (typeof this.shop.categories[i].children !== 'undefined') {
                    tempObject['children'] = this.checkChildrenCategories(this.shop.categories[i].children);
                }
                data.push(tempObject);
            }
            return data;
        },
    },
    methods: {
        checkChildrenCategories(categories) {
            const data = [];
            for (let i = 0; i < categories.length; i++) {
                const tempObject = {
                    id: categories[i].id,
                    label: categories[i].label,
                    isDisabled: this.checkIdMapped(categories[i].id),
                };
                // For children - recursive categories
                if (typeof categories[i].children !== 'undefined') {
                    tempObject['children'] = this.checkChildrenCategories(categories[i].children);
                }
                data.push(tempObject);
            }
            return data;
        },
        checkIdMapped(id) {
            for (let i = 0; i < this.shop.mapped_categories.length; i++) {
                if (id === this.shop.mapped_categories[i].shopCategory) {
                    return true;
                }
            }
            return false;
        },
        syncShopCategories() {
            this.$store
                .dispatch('integrations/getShopCategories', {
                    _id: this.$route.params.id,
                })
                .then((res) => {
                    if (res.error === 0 && typeof res.message.categories !== 'undefined') {
                        this.shop.product_features = res.message.product_features;
                        this.shop.product_options = res.message.product_options;
                        this.saveCategoryMappings();
                    }
                    if (res.error && typeof res.message.message !== 'undefined') {
                        this.$notify.error(res.message.message);
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'syncShopCategories',
                        },
                    });
                });
        },
        syncShopProductsCharacteristics() {
            this.$store
                .dispatch('integrations/getShopProductCharacteristics', {
                    _id: this.$route.params.id,
                })
                .then((res) => {
                    if (res.error === 0 && typeof res.message.product_features !== 'undefined') {
                        this.shop.product_features = res.message.product_features;
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'syncShopProductsCharacteristics',
                        },
                    });
                });
        },
        syncShopProductOptions() {
            this.$store
                .dispatch('integrations/getShopProductOptions', {
                    _id: this.$route.params.id,
                })
                .then((res) => {
                    if (res.error === 0 && typeof res.message.product_options !== 'undefined') {
                        this.shop.product_options = res.message.product_options;
                    }
                })
                .catch((err) => {
                    this.$notify.error('Err');
                });
        },
        newCategoriesMapping() {
            this.shop.mapped_categories.push({
                celCategory: null,
                shopCategory: null,
                active: true,
                commission: 0,
            });
        },
        removeCategoryMapping(index) {
            this.shop.mapped_categories.splice(index, 1);
        },
        saveCategoryMappings() {
            this.$emit('update:shop', this.shop);
            this.$store
                .dispatch('integrations/storeShopMappedCategories', {
                    _id: this.$route.params.id,
                    mapped_categories: this.shop.mapped_categories,
                })
                .then((res) => {
                    if (res.error === 0 && typeof res.message.success) {
                        this.shop.mapped_categories = res.message.mapped_categories;
                        this.shop.categories = res.message.categories;
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'saveCategoryMappings',
                        },
                    });
                });
        },

        // Characteristics and Attributes
        showCharTab(index, celCategory) {
            this.shopActiveCategoryMapTab = this.shopActiveCategoryMapTab === index ? null : index;

            if (typeof this.celCharacteristics[celCategory] !== 'undefined' || this.shopActiveCategoryMapTab === null) {
                return;
            }

            this.celCharacteristics[celCategory] = {};

            this.$store
                .dispatch('integrations/getCategCharacts', {
                    categ_id: celCategory,
                })
                .then((res) => {
                    if (res.error === 0) {
                        const celCharacteristics = Object.keys(res.message).map((i) => res.message[i]);
                        celCharacteristics.sort((a, b) => (a.priority < b.priority ? 1 : -1));
                        // this.celCharacteristics[celCategory] = celCharacteristics
                        this.$set(this.celCharacteristics, celCategory, celCharacteristics);
                        let tempObj = {};
                        celCharacteristics.forEach((item, index) => {
                            tempObj = {
                                ...tempObj,
                                ...{
                                    [item.charact_id]: {
                                        cel_charact_id: item.charact_id,
                                        cel_charact_name: item.charact_name,
                                        product_feature: objGet(
                                            this.shop,
                                            'mapped_categories_characteristics' +
                                                '.' +
                                                celCategory +
                                                '.' +
                                                item.charact_id +
                                                '.' +
                                                'product_feature',
                                            null
                                        ),
                                        product_option: objGet(
                                            this.shop,
                                            'mapped_categories_characteristics' +
                                                '.' +
                                                celCategory +
                                                '.' +
                                                item.charact_id +
                                                '.' +
                                                'product_option',
                                            null
                                        ),
                                    },
                                },
                            };
                        });

                        Object.keys(tempObj).forEach((key) => {
                            if (!tempObj[key].product_feature) {
                                const denumire_caracteristica_cel = tempObj[key].cel_charact_name
                                    .replace(':', '')
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '');
                                const caracteristica_gasita_db = this.shop.product_features.find(
                                    (caracteristica) => caracteristica.label === denumire_caracteristica_cel
                                );

                                if (caracteristica_gasita_db) {
                                    tempObj[key].product_feature = caracteristica_gasita_db.id;
                                }
                            }
                        });

                        this.celMappedCharacteristics[celCategory] = tempObj;
                    }
                })
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'showCharTab',
                        },
                    });
                });
        },
        syncCategoryCharacteristics(celCategory) {
            this.$store
                .dispatch('integrations/storeShopMappedCategoryCharacteristics', {
                    _id: this.$route.params.id,
                    celMappedCharacteristics: this.celMappedCharacteristics,
                })
                .then((res) => {})
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'syncCategoryCharacteristics',
                        },
                    });
                });
        },
        openModal() {
            if (this.shop.platform === 'Prestashop') {
                this.dialogTutorialsVisible = !this.dialogTutorialsVisible;
            }
        },
        celCategorySelected() {
            if (
                Object.entries(this.categoriesCommission).length === 0 &&
                this.categoriesCommission.constructor === Object
            ) {
                this.$store
                    .dispatch('integrations/getCategoryCommission', {
                        _id: this.$route.params.id,
                    })
                    .then((res) => {
                        if (res.error === 0 && res.message.success) {
                            this.categoriesCommission = res.message.categoriesCommission;
                        }
                    })
                    .catch((err) => {
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'celCategorySelected',
                            },
                        });
                    });
            }
        },
        updateAdaos(event, mappedCategoryIndex) {
            const celCategory = event.id;
            if (
                Object.entries(this.categoriesCommission).length !== 0 &&
                typeof this.categoriesCommission[celCategory] !== 'undefined'
            ) {
                this.shop.mapped_categories[mappedCategoryIndex]['commission'] = this.categoriesCommission[celCategory];
            }
        },
    },
};
</script>

<style scoped>
.on-hov-shadow:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 0.5rem 0.7rem !important;
    background: linear-gradient(45deg, #e2e2e2, transparent);
}

::v-deep .custom_dialog > .el-dialog__body {
    padding: 0;
}
::v-deep .custom_dialog > .el-dialog__header {
    display: none;
}

.small-margin {
    margin-left: 2px;
    margin-right: 2px;
}

.treeselect_node_el {
    color: rgb(78, 72, 72) !important;
}

#select_map_cat >>> .vue-treeselect__option--disabled .vue-treeselect__label-container {
    cursor: pointer !important;
}

#select_map_cat >>> label {
    cursor: pointer !important;
}
</style>
